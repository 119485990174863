import React from "react";
import "../scss/LandingPage.scss";
import Hero from "../assets/images/LandingPage/hero.png";
import Credit from "../assets/images/LandingPage/credit.svg";
import Issue from "../assets/images/LandingPage/issue.png";
import Track from "../assets/images/LandingPage/track.svg";
import Insight from "../assets/images/LandingPage/insight.png";
import LandingFaq from "../components/Faq/LandingFaq";
import Raise from "../assets/images/LandingPage/raise.png";
import Team from "../assets/images/LandingPage/team.png";
import Control from "../assets/images/LandingPage/control.png";
import Count from "../assets/images/LandingPage/count.png";
import Dynamic from "../assets/icons/LandingPage/dynamic.svg";
import Free from "../assets/icons/LandingPage/free.svg";
import Instant from "../assets/icons/LandingPage/instant.svg";
import Revolving from "../assets/icons/LandingPage/revolving.svg";
import Reward from "../assets/icons/LandingPage/reward.svg";
import Save from "../assets/icons/LandingPage/save.svg";
import Card from "../assets/images/LandingPage/card.png";
// import Slide from "react-reveal/Slide";
import { useTypewriter } from "react-simple-typewriter";
import Jive from "../assets/images/LandingPage/jive.png";
import Genesys from "../assets/images/LandingPage/genesys.jpg";
import iExchange from "../assets/images/LandingPage/iexchange.svg";
import Filtarhq from "../assets/images/LandingPage/filtarhq.png";

import { FiArrowRight, FiArrowDown } from "react-icons/fi";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const { text } = useTypewriter({
    words: [
      "founders.",
      "startups.",
      "e-commerce.",
      "enterprise.",
      "internet businesses.",
      "every business.",
    ],
    loop: 0,
  });
  return (
    <div className="landingPage">
      <div className="landingPage__bgg">
        <div className="landingPage__wrapper">
          <div className="landingPage__container">
            {/* <Slide left> */}
              <div className="landingPage__containerLeft">
                <h1 className="landingPage__containerLeft__title">
                  The corporate credit card designed to support your business
                  finances.
                </h1>
                <br />
                <div className="landingPage__containerLeft__animate">
                  <h1>
                    Designed for <span>{text}</span>
                  </h1>
                </div>
              </div>
            {/* </Slide> */}
            

            <div className="landingPage__form">
              <div className="landingPage__formContainer">
                <div className="landingPage__formWrapper">
                  <input type="text" placeholder="Enter your work email" />
                  <button
                    onClick={() =>
                      window
                        .open("https://forms.gle/E94PHGX75aU3NwWf8", "_blank")
                        .focus()
                    }
                    type="button"
                  >
                    Get Access
                  </button>
                </div>
                <div>
                  {/* <img src={Mark1} alt="mark" /> */}
                  <p>It just takes 5mins to get access....</p>
                </div>
              </div>
            </div>
          </div>

          {/* <Slide right> */}
            <div className="landingPage__containerRight">
              <img src={Hero} alt="hero" />
            </div>
          {/* </Slide> */}

          <div className="landingPage__sponsors">
            <p>
              Trusted by <br /> hundreds of <br /> companies.
            </p>
            <div>
              <img src={Jive} alt="" />
              <img src={Genesys} alt="" />
              <img src={Filtarhq} alt="" />
              <img src={iExchange} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="landingPage__hero">
        <div className="landingPage__heroContainer">
          <div className="landingPage__heroTop">
            <h1>
              We’re changing the game,
              <br />
              the narrative of the past.
            </h1>
          </div>

          <button
            onClick={() =>
              window
                .open("https://forms.gle/E94PHGX75aU3NwWf8", "_blank")
                .focus()
            }
          >
            <p>Get Your Card</p>
            <div>
              <FiArrowRight />
            </div>
          </button>

          <div className="landingPage__heroBottom">
            <div
              className="landingPage__heroBottom__instant"
              data-aos="zoom-in"
            >
              <img src={Instant} alt="Instant" />
              <h2>Instant access</h2>
              <p>
                Signup and get approved with a virtual card(s) in minutes; While
                physical card(s) will be delivered in 3-4 business days
              </p>
            </div>

            <div className="landingPage__heroBottom__free" data-aos="zoom-in">
              <img src={Free} alt="free" />
              <h2>Its free to use</h2>
              <ul>
                <li>No personal guarantee/liability</li>
                <li>No hidden/annual fees</li>
                <li>Zero (0%) Interest</li>
              </ul>
            </div>
            <div className="landingPage__heroBottom__reward" data-aos="zoom-in">
              <img src={Reward} alt="Reward" />
              <h2>Rewards</h2>
              <p>
                Earn cashbacks and discounts on every purchases/transactions.
              </p>
            </div>

            <div
              className="landingPage__heroBottom__revolving"
              data-aos="zoom-in"
            >
              <img src={Revolving} alt="Revolving" />
              <h2>Revolving high limits</h2>
              <p>
                Credit Limits are 10times higher than any competitive cards,
                traditional small credits or drafts.
              </p>
            </div>

            <div
              className="landingPage__heroBottom__dynamic"
              data-aos="zoom-in"
            >
              <img src={Dynamic} alt="Dynamic" />
              <h2>Dynamic Credit</h2>
              <p>
                Credit limits that rise along with your business’s cash flow.
                Get limits of up to 75% of your business’s monthly sales (with
                maximum credit limit of N50 million).
              </p>
            </div>

            <div className="landingPage__heroBottom__save" data-aos="zoom-in">
              <img src={Save} alt="Save" />
              <h2>Save time</h2>
              <p>
                Save time in bookkeeping with built-in expense management tools.
              </p>
            </div>

            <div className="landingPage__heroCard" data-aos="zoom-out">
              <img src={Card} alt="card" />
            </div>
          </div>
        </div>
      </div>

      <section className="landingPage__section">
        <section className="landingPage__sectionContainer">
          <div className="landingPage__sectionWrapper">
            <div data-aos="fade-right">
              <img src={Credit} alt="credit" />
            </div>
            <div data-aos="fade-left">
              <h2>Business credit, with zero interest</h2>
              <p>
                No matter how big or small your company is, Evea makes it simple
                to get the funds you need for your operations. Access a credit
                line in minutes and begin spending intelligently.
              </p>
              <div className="landingPage__links">
                <Link to="/" className="landingPage__link">
                  See if you qualify
                  <FiArrowDown />{" "}
                </Link>
                <Link to="/business-card" className="landingPage__link">
                  Learn More <FiArrowRight />{" "}
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="landingPage__bg1">
          <section className="landingPage__sectionContainer">
            <div className="landingPage__sectionWrapper">
              <div data-aos="fade-up">
                <h2>Issue and manage your cards.</h2>
                <p>
                  Upon approval, instantly create an unlimited number of virtual
                  cards (for online-purchases, subscriptions, vendors) and 1
                  physical card per user. Set spending limits on cards issued,
                  as well as manage the use of the cards, either to freeze, or
                  unfreeze, or terminate card(s) as needed.
                </p>

                <Link to="/business-card" className="landingPage__link">
                  Learn More <FiArrowRight />{" "}
                </Link>
              </div>

              <div data-aos="fade-down">
                <img src={Issue} alt="credit" />
              </div>
            </div>
          </section>
          <section className="landingPage__sectionContainer">
            <div className="landingPage__sectionWrapper">
              <div data-aos="fade-right">
                <h2>Track expenses, real-time.</h2>
                <p>
                  Track spend as it happens, auto-match your receipt, and much
                  more.
                </p>
                <Link to="/expense-management" className="landingPage__link">
                  Learn More <FiArrowRight />{" "}
                </Link>
              </div>

              <div data-aos="fade-left">
                <img src={Track} alt="credit" />
              </div>
            </div>
          </section>
        </div>

        <section className="landingPage__sectionContainer">
          <div className="landingPage__sectionWrapper">
            <div data-aos="fade-right">
              <img src={Insight} alt="credit" />
            </div>
            <div data-aos="fade-left">
              <h2>Insight into company spend.</h2>
              <p>
                All spending is categorized by color. With the expense tracking
                feature, you can view your company’s entire spending patterns
                (in color-coded categories) in real-time. Every purchase gets
                categorized and assigned a color. the color-coded categories
                make it easy to spot patterns in your spending so you can choose
                if you need to revise.
              </p>
            </div>
          </div>
        </section>

        <div className="landingPage__bg2">
          <div className="landingPage__bg22">
            <div className="landingPage__bg222">
              <div className="landingPage__bg2222">
                <section className="landingPage__sectionContainer">
                  <div className="landingPage__sectionWrapper">
                    <div data-aos="fade-up">
                      <img src={Raise} alt="credit" />
                    </div>
                    <div data-aos="fade-down">
                      <h2>
                        Raise your credit limit in-proportion to your business
                        earnings.
                      </h2>
                      <p>
                        Your credit limit increases in tandem with your
                        company’s cash flow.
                      </p>
                    </div>
                  </div>
                </section>

                <section className="landingPage__sectionContainer">
                  <div className="landingPage__sectionWrapper">
                    <div data-aos="fade-down">
                      <h2>
                        Cards for your whole team (Team collaboration).{" "}
                        <span>Coming Soon</span>
                      </h2>
                      <p>
                        Add key team members to have access to Evea, by issuing
                        cards to them with custom spending limit, and track
                        their expenses. Though you have absolute control on all
                        cards issued
                      </p>
                    </div>

                    <div data-aos="fade-up">
                      <img src={Team} alt="credit" />
                    </div>
                  </div>
                </section>

                <section className="landingPage__sectionContainer">
                  <div className="landingPage__sectionWrapper">
                    <div data-aos="fade-right">
                      <img src={Control} alt="credit" />
                    </div>
                    <div data-aos="fade-left">
                      <h2>
                        Control your budgets. <span>Coming Soon</span>
                      </h2>
                      <p>
                        Set up budgets by department, team, or project, then add
                        or remove team member(s)/personal as needed. Budgeting
                        in Evea is a powerful tool to control spend effectively,
                        for all employees’ cards, spend and limits will be tied
                        to budget(s) they belong in.
                      </p>
                      <Link
                        to="/spend-management"
                        className="landingPage__link"
                      >
                        Learn More <FiArrowRight />{" "}
                      </Link>
                    </div>
                  </div>
                </section>

                <section className="landingPage__sectionContainer">
                  <div className="landingPage__sectionWrapper">
                    <div data-aos="fade-right">
                      <h2>Count on us.</h2>
                      <p>
                        From handling credit increase to reaching out if you’re
                        approaching your limit, our skilled team is by your side
                        every step of the journey.
                      </p>
                    </div>

                    <div data-aos="fade-left">
                      <img src={Count} alt="credit" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="businessCard__faq">
        <div className="businessCard__faqContainer">
          <div className="businessCard__faq__topic">
            <p>Frequently asked questions</p>
            <div>
              <p>
                Having trouble finding what you’re looking for? Contact our team
                for immediate assistance via phone, chat, or email, or consult
                the <a href="/">Help Center</a> at any time.
              </p>
              <a href="/contact-us">Contact us</a>
            </div>
          </div>
          <LandingFaq />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
